.sidebar {
    height: calc(100vh - 112px);
    padding: 16px 20px 20px 20px;
    background-color: #f7f7f7;
    overflow: auto;
    overflow-x: hidden;
}

.textfield_label {
    font-size: 13px !important;
    color: #555 !important;
}

.textfield_label1{
    font-size: 11.5px !important;
    color: #555 !important;
}

.Tip {
    display: none !important;
}

.dividerstyle {
    background: #ff3333 !important; 
    opacity: 0.2 !important;
    margin: 8px 0px 0px 0px !important;
}

.AreaHighlight {
    border: 1px solid #333;
    background-color: rgba(252, 232, 151, 0.8);
    opacity: 1;
    mix-blend-mode: multiply;
}

.AreaHighlight__part {
    cursor: text !important;
    position: absolute !important;
    background: rgba(255, 255, 255, 0.04) !important;
    transition: background 0.3s;
}

.AreaHighlight--scrolledTo .AreaHighlight__part {
    background: rgba(255, 66, 66, 1);
    color: #ffffff !important
}

.Highlight {
    position: absolute;
}

.Highlight__emoji {
    position: absolute;
    color: black;
    opacity: 1;
    font-size: 28px;
}

.Highlight__parts {
    opacity: 1;
    background: #ffffff !important;
}

.Highlight__part {
    cursor: text !important;
    position: absolute !important;
    background: rgba(255, 255, 255, 0.04) !important;
    transition: background 0.3s;
}

.Highlight__popup {
    background-color: #3d464d;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    max-width: 300px;
    max-height: 100px;
    overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
    background: rgba(2, 254, 228, 1) !important;
}

.MouseSelection {
    position: absolute;
    border: 1px dashed #333;
    background: rgba(0, 0, 0, 0);
    mix-blend-mode: multiply;
}
  /* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .MouseSelection {opacity: 0.5 }
}

  /* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
    .MouseSelection {opacity: 0.5 }


.PdfHighlighter {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.PdfHighlighter__highlight-layer {
    position: absolute;
    z-index: 3;
    left: 0;
}

.PdfHighlighter__tip-container {
    z-index: 6;
    position: absolute;
}

.PdfHighlighter--disable-selection {
    user-select: none;
    pointer-events: none;
}}

.textLayer {
    z-index: 2;
    opacity: 1;
    mix-blend-mode: multiply;
    display: flex;
}

.annotationLayer {
    position: absolute;
    top: 0;

    z-index: 3;
}

html
    body
    .textLayer
    > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
    opacity: 1;
    mix-blend-mode: multiply;
}

// .textLayer ::selection {
//     background: rgba(252, 232, 151, 1);
//     mix-blend-mode: multiply;
// }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {opacity: 0.5;}
}

  /* Internet Explorer support method */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {opacity: 0.5 }
  }
  
  /* Microsoft Edge Browser 12+ (All) - @supports method */
  @supports (-ms-ime-align:auto) {
    .textLayer {opacity: 0.5 }
  }

.topbarContainer {
    background-color: #ddd; 
    padding: 4px 16px;
};

.sidebarHeading {
    font-size: 18px;
    font-weight: 500;
};

.sidebar_container {
    width: 50vw; 
    position: relative;
}

.invoice_preivew--topbar {
    background-color: #ebeaf2;
    height: 50px;
    //padding:'8px 0px'
}

.invoice_preivew--action__button {
    color: #333; 
    font-size:'14px';
}

.invoice_preivew--action__button:hover {
    background-color: transparent;
}

.invoice_detials--container {
    position: relative;
    margin-bottom: 35px;
    box-shadow: 0px 0px 6px #ccc;
    border-radius: 5px;
}

.invoice_details {
    padding: 0px 28px 32px 28px;
}

.tableheading_item_style {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #fff !important
}

.ribbon {
    position: absolute !important;
    top: -5px;
    left: -5px;
    overflow: hidden;
    width: 96px;
    height: 94px;
}

.ribbon_text-ellipsis { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ribbon .ribbon-inner {
    text-align: center;
    color: #fff;
    top: 25px;
    left: -31px;
    width: 135px;
    padding: 3px;
    position: relative;
    transform: rotate(-45deg);
}

.ribbon .ribbon-inner:before,.ribbon .ribbon-inner:after {
    content: "";
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-left-color: inherit;
    border-right: 5px solid transparent;
    border-bottom: 5px solid;
    border-bottom-color: inherit;
    position: absolute;
    top: 20px;
    transform: rotate(-45deg)
}

.ribbon .ribbon-inner:before {
    left: 0;
    border-left: 2px solid transparent;
    color: #2e8fda;
}

.ribbon .ribbon-inner:after {
    right: -3px;
    border-bottom: 3px solid transparent;
    color: #2e8fda
}

.ribbon .ribbon-draft {
    background-color: #94a5a6;
    border-color: #788e8f !important;
}

.ribbon .ribbon-overdue {
    background-color: #D24E4E;
    border-color: #D24E4E !important;
}

.ribbon .ribbon-posted {
    background-color: #268ddd;
    border-color: #1c72b4 !important;
}

.ribbon .ribbon-success {
    background-color: #1fcd6d;
    border-color: #18a155 !important;
}

.mandatory_fields {
    color: #D24E4E !important;
}

.invoice_preview--style {
    padding: 32px 16px 16px 16px !important;
    height: calc(100vh - 100px) !important;
    overflow: auto !important;
}

.invoice_preview--one {
    padding: 16px !important;
    height: calc(100vh - 48px) !important;
    overflow: auto !important;
}

.paymentSize {
    font-size: 13px !important;
    color: #2485e8 !important;
    background: #f3f8fe !important;
    padding: 4px 6px !important;
    border-radius: 24px !important;
    margin-left: 6px !important ;
}