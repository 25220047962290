.mainContainer {
    width: 100vw;
    height: 100vh;
    overflow: auto
}

.logoStyle {
    width: 220px;
    height: 49.18px;
    @media (max-width: 1400px) {
        width: 190px;
        height: 40px;
    }
};

.onboarding_button {
    min-width: 130px !important;
    color: white;
    margin-right: 3% !important;
}

.tab {
    min-width: 15px;
    height: 8px !important;
    margin-left: 4px;
    background: #cbcbcb;
    border-radius: 10px;
    cursor: pointer;
    
};

.tab:first-child {
    margin-left: 0px;
}

.tabSelected {
    width: 50px;
    background-color: #555555 !important;
};
