
.filterbtn_styles {
    border-radius: 5px;
    border: 1px solid #ccc !important;
    padding-left:16px; 
    padding-right:16px;
    color: #555;
}

.icon_styles {
    margin-right: 8px; 
    color: #555; 
    font-size: 18px;
}

.customColumn_one {
    flex-basis: 32.5% !important;
    max-width: 32.5% !important;
}

.customColumn_two {
    flex-basis: 34% !important;
    max-width: 34% !important;
}

.tableheading_style {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #222 !important
}

.tablebody_style {
    font-size: 12px !important;
    color: #333 !important
}

.tablebody_invoice__style {
    font-size: 14px !important;
    color: #999 !important
}

.tablefooter__style {
    color: #888 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important;
}

.text__ellipsis {
    max-width: auto !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.menu{
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: 0;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
}

.menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.text__ellipsis1 {
    max-width: 180px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.text__ellipsis2 {
    max-width: 150px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

/**spinning refresh icon**/
.spinner {
    animation: spin-animation 0.5s infinite;
    display: inline-block;
}

.spinner {
    animation: spin-animation 0.5s infinite;
    display: inline-block;
}

.spinner:hover {
    background: none !important;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.tablecell_style_one {
    font-size: 14px !important;
    border: transparent !important;
}

.tablerow_style_one {
    box-shadow: 0px 1px 2px 0px #00000033;
    border-radius: 5px !important;
}

.tableheading_style_one {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #222 !important;
    border: transparent !important
}   

.disableRow {
    border-left: 3px solid #D24E4E !important;
}

.row {
    border-left: 3px solid #34A853 !important;
}

.deletebtn_style {
    display: flex !important; 
    align-items: center !important;
    justify-content: center !important; 
    font-size: 12px !important;  
    background: #fff !important;
    border: 1px solid darkgray !important;
    color: grey !important;
}


.text__ellipsis3 {
    max-width: 85px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
