.newbtn__style {
    width: 100px !important;
    border: 1px solid #1A73E8 !important;
    color: #1A73E8 !important;
}

.createheader_incons {
    color: #555 !important;
    margin-right: 8px !important;
    font-size: 20px !important;
}