.component_container {
    width:  100% !important;
    display: flex !important; 
    flex-direction: column !important; 
    gap:0px !important;
    margin-bottom: 32px !important;
    // /margin-top: 16px; 
}

.paper_style {
    display: flex; 
    flex-direction: column; 
    background-color: #fff;
}

.button_styles {
    width: 100px !important;
}

.flex_row {
    display: flex;
    align-items: center;
}

.flex_column {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.drawer_container {
    padding: 16px;
    position: relative;
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.drawer_footer {
    position: absolute; 
    bottom: 0;
    right: 0;
    left: 0;
    display: flex; 
    gap: 12px;
    align-items: center;
    padding: 8px 16px;
    border-top: 1px solid #ccc;
}

.drawer_footer_btn {
    width: 110px;
}

.tabStyle{
    .MuiTabs-root {
        min-height: 24px !important;
    }
    .MuiTab-root {
        padding: 7px 8px !important
    }   
}

.react-datepicker{
    /* border-left:  1px solid #ccc !important;
    border-right:  1px solid #ccc !important;
    border-bottom:  1px solid #ccc !important;
    border-bottom:  1px solid #ccc !important; */
    border: none !important;
    border-top:  1px solid #aeaeae !important;
    border-radius: 0 !important;
    width: 238px !important;
}

.react-datepicker__header{
    background-color: transparent !important;
}

.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    border-width: 1px 1px 0 0 !important;
    border-color: #212121 !important;
}

.errorContainer {
    padding: 16px;
    border: 1px solid #ff3333;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 8px
}

.table_head_style { 
    border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
    background: #F3F6F9 !important;
    font-size: 15px !important;
    color: #212121 !important;
    font-weight: 600 !important;
}

.error_message {
    color: #D24E4E;
    background: #ffe28f;
    font-size: 13px;
    padding: 2px 6px;
    position: relative;
    top: -48px;
}

.link_styles {
    color: #20a8e0 !important;
    cursor: pointer !important;
    font-size: 13px !important;
    margin: 0px 1px 0px 8px !important;
};

.image_styles {
    width: 21px !important;
}

.container_margin {
    margin: 0px 28px !important;
}